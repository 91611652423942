import {directus} from "@/store";

const generateImageLink = async (
    imageId,
    width = 50,
    height = 50,
) => {
    const assetsBaseURL = `https://ppg-rawmaterials-api.brndcntrl.com/assets`;
    const imageLink = `${assetsBaseURL}/${imageId}?access_token=${directus.auth.token}&width=${width}&height=${height}`;

    return imageLink;
};

export default generateImageLink;
