<template>
	<div class="table">
		<div class="table__title">
			{{ title }}
		</div>

		<PurchaseOrderDialog :show="showPurchaseOrderDialog" @hide="showPurchaseOrderDialog = false" :orderline="null" :rawmaterial="selectedMaterial" position="top" :modal="true" />
		<ExportRawMaterialLabelsPDFDialog :show="showExportLabelsPDFDialog" @hide="showExportLabelsPDFDialog = false" :selected-materials="selectedMaterials" position="center" :modal="true" />
		<Dialog class="table__dialog" :visible.sync="showAddToCartDialog" :style="{ 'width': '30vw', 'line-height': '30px' }" position="top" :modal="true">
			<template #header>
				<h3>{{ selectedMaterial.name }}</h3>
			</template>
			<div>
				<table>
					<tr>
						<td class="font-bold pr-4">SAP</td>
						<td>{{ selectedMaterial.sapcode }}</td>
					</tr>
					<tr>
						<td class="font-bold pr-4">Packaging</td>
						<td>{{ selectedMaterial.packaging }}</td>
					</tr>
					<tr>
						<td class="font-bold pr-4">On stock</td>
						<td>{{ selectedMaterial.stockSum }}</td>
					</tr>
					<tr>
						<td class="font-bold pr-4">Quantity</td>
						<td>
							<InputNumber type="text" :min="1" :max="999" v-model="quantityInput" @input="quantityValid = false" :class="quantityValid ? 'p-invalid' : ''" class="p-inputtext-sm mt-3" autofocus />
						</td>
					</tr>
					<tr>
						<td class="font-bold pr-4">Urgent</td>
						<td>
							<Checkbox id="binary" v-model="urgent" :binary="true" />
						</td>
					</tr>
				</table>
			</div>

			<template #footer>
				<Button label="Cancel" @click="showAddToCartDialog = false" class="p-button-text" />
				<Button label="Add to cart" @click="addToCartHandler()" icon="pi pi-shopping-cart" />
			</template>
		</Dialog>
		<DataTable :selection.sync="selectedMaterials" dataKey="id" :paginator="true" ref="dt" :rows="20" responsiveLayout="scroll" paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" :rowsPerPageOptions="[10, 20, 50]" class="p-datatable-sm p-datatable-striped boxshadow" :value="rawmaterials" showGridlines :filters.sync="filters" filterDisplay="row">
			<template #header>
				<div class="flex mx-4">
					<div v-if="checkPermission('purchase_write')">
						<Button icon="pi pi-external-link" label="Export" @click="exportCSV($event)" />
					</div>
					<div v-if="checkPermission('purchase_write')">
						<Button class="ml-2" icon="pi pi-file-pdf" label="SHIS Labels" @click="exportPDF($event)" />
					</div>
				</div>
			</template>
			<Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
			<Column field="sapcode" header="SAP" key="sapcode" sortable>
				<template #body="{ data }">
					{{ data.sapcode }}
				</template>
				<template #filter="{ filterModel, filterCallback }">
					<InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter" />
				</template>
			</Column>
			<Column field="champcode" header="Champ" key="champcode" sortable>
				<template #body="{ data }">
					{{ data.champcode }}
				</template>
				<template #filter="{ filterModel, filterCallback }">
					<InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter" />
				</template>
			</Column>
			<Column field="name" header="Name" key="name" sortable>
				<template #body="{ data }">
					{{ data.name }}
				</template>
				<template #filter="{ filterModel, filterCallback }">
					<InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter" />
				</template>
			</Column>
			<Column field="id" header="GHS" key="ghs">
				<template #body="{ data }">
					<img v-for="ghs in data.ghs" :key="ghs.id" :src="getGHSImageUrl(ghs)" />
				</template>
			</Column>
			<Column field="packaging" header="Packaging" key="packaging" sortable> </Column>
			<Column field="weight" header="Weight kg per package" key="weight" sortable>
				<template #body="{ data }">
					<div v-if="data.weight">{{ parseFloat(data.weight).toFixed(2) }} kg</div>
				</template>
			</Column>
			<Column v-if="checkPermission('stock_read')" field="stockSum2" header="Stock" sortable>
				<template #body="{ data }">
					<div @click="orderAtSupplier(data)" :class="checkPermission('stock_write') ? 'cursor-pointer' : ''">
						<Badge v-if="data.stockSum <= 0" value="Out of stock" severity="danger" />
						<Badge v-else-if="data.stockSum <= data.stock_min" value="Critical stock" severity="warning" />
						<Badge v-else-if="data.stockSum > data.stock_min" value="On stock" severity="success" />
					</div>
				</template>
			</Column>
			<Column v-if="checkPermission('stock_read')" field="stockSum" header="On stock" sortable>
				<template #body="{ data }">
					{{ data.stockSum }}
				</template>
			</Column>
			<Column v-if="checkPermission('stock_read')" field="stock_min" header="Min stock" key="stock_min" sortable> </Column>
			<Column v-if="checkPermission('stock_read')" field="stock_max" header="Max stock" key="stock_max" sortable> </Column>
			<Column field="location" header="Location Warehouse" key="location" sortable>
				<template #body="{ data }">
					{{ data.location }}
				</template>
				<template #filter="{ filterModel, filterCallback }">
					<InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter" />
				</template>
			</Column>
			<Column field="supplier" header="Order from" key="orderfrom" sortable>
				<template #body="{ data }">
					<div v-if="suppliers.find((o) => o.id === data.supplier)">
						{{ suppliers.find((o) => o.id === data.supplier).code }}
					</div>
				</template>
			</Column>
			<Column field="" header="Order" key="id" :exportable="false">
				<template #body="slotProps">
					<Button v-if="1 == 1" @click="showAddToCartModalHandler(slotProps.data)" label="Add to cart" icon="pi pi-shopping-cart" iconPos="left" class="p-button-sm" :class="slotProps.data.stockSum > 0 ? 'p-button-success' : 'p-button-danger'" />
				</template>
			</Column>

			<template #footer>
				<div class="table__footer"><strong>For questions please contact:</strong> RDA Warehouse | <strong>E:</strong> <a href="mailto:SR0734@ppg.com"> SR0734@ppg.com</a> |<strong> P:</strong> +31 20 407 53 12</div>
			</template>
		</DataTable>
	</div>
</template>

<script>
import { directus } from "../store";
import { mapState } from "vuex";

import PurchaseOrderDialog from "@/components/PurchaseOrderDialog.vue";
import ExportRawMaterialLabelsPDFDialog from "@/components/ExportRawMaterialLabelsPDFDialog.vue";

import { FilterMatchMode, FilterOperator } from "primevue/api/";

export default {
	name: "rawmaterials",
	components: { PurchaseOrderDialog, ExportRawMaterialLabelsPDFDialog },
	data() {
		return {
			title: "Raw Materials",
			filters: {
				sapcode: {
					value: null,
					matchMode: FilterMatchMode.CONTAINS,
				},
				champcode: {
					value: null,
					matchMode: FilterMatchMode.CONTAINS,
				},
				name: {
					value: null,
					matchMode: FilterMatchMode.CONTAINS,
				},
				location: {
					value: null,
					matchMode: FilterMatchMode.CONTAINS,
				},
			},
			showAddToCartDialog: false,
			showPurchaseOrderDialog: false,
			showExportLabelsPDFDialog: false,
			selectedMaterial: {
				name: "",
				sap: "",
				packaging: "",
				stock: [
					{
						quantity: 0,
					},
				],
			},
			quantityInput: 1,
			quantityValid: false,
			urgent: false,
			selectedMaterials: [],
		};
	},
	created() {},
	async mounted() {
		await this.getData();
	},
	methods: {
		getData() {
			//console.log(this.$store);
			this.$store.dispatch("getData");
		},
		getGHSImageUrl(ghs) {
			try {
				return this.$store.state.DIRECTUS_URL_ASSETS + ghs.GHS_id.image.id + "?access_token=" + directus.auth.token + "&fit=cover&width=25&height=25&quality=100";
			} catch {
				this.$store.dispatch("logout");
			}
		},
		showAddToCartModalHandler(material) {
			this.quantityInput = 1;
			this.showAddToCartDialog = true;
			this.selectedMaterial = material;
		},
		addToCartHandler() {
			if (this.quantityInput > 0) {
				this.selectedMaterial.quantityToAdd = Number(this.quantityInput);
				this.selectedMaterial.urgent = this.urgent;
				this.selectedMaterial.description = this.selectedMaterial.name;
				this.$store.dispatch("addToCart", this.selectedMaterial);
				this.showAddToCartDialog = false;
				this.quantityValid = false;
			} else {
				this.quantityValid = true;
			}
		},
		async orderAtSupplier(payload) {
			if (this.checkPermission("purchase_write")) {
				this.selectedMaterial = JSON.parse(JSON.stringify(payload));
				this.showPurchaseOrderDialog = true;
			}
		},
		exportCSV() {
			this.$refs.dt.exportCSV();
		},
		async exportPDF() {
			this.showExportLabelsPDFDialog = true;
		},
	},
	computed: {
		...mapState({
			rawmaterials: (state) => state.data.rawmaterials,
			suppliers: (state) => state.data.suppliers,
			cart: (state) => state.cart,
		}),
	},
};
</script>

<style lang="scss">
.table {
	padding: 20px;
	&__title {
		color: #24222f;
		text-align: left;
		font-weight: bold;
		margin: 20px 0;
		font-size: 28px;
	}
	&__dialog {
		text-align: left;
	}
	&__footer {
		text-align: left;
	}
}
</style>
