import { PDFDocument } from "pdf-lib";
import generateSmallLabelsPagePDF from "@/utils/generateSmallLabelsPagePDF";
import splitArrayIntoGroups from "@/utils/splitArrayIntoGroups";

const generateSmallLabelsPDF = async (labelsData, startingPosition=0, usePageTemplate=false, useLabelTemplate=false) => {
    let singleLabelPdfBytes
    if (useLabelTemplate) {
        const SINGLE_LABEL_PDF_PATH = "pdf/small_label.pdf";
        const singleLabelPdfResponse = await fetch(SINGLE_LABEL_PDF_PATH);
        if (!singleLabelPdfResponse.ok) {
            alert("Failed to load the PDF file.");
            return;
        }
        singleLabelPdfBytes = await singleLabelPdfResponse.arrayBuffer();
    } else {
        const templatePdf = await PDFDocument.create();
        const templateHeight = 2.76 * 72
        const templateWidth = 7.86 * 72
        templatePdf.addPage([templateWidth, templateHeight])
        singleLabelPdfBytes = await templatePdf.save();
    }

    let pageTemplatePdfBytes
    if (usePageTemplate) {
         const PAGE_TEMPLATE_PDF_PATH = "pdf/small_labels_page_template.pdf";
        const pageTemplatePdfResponse = await fetch(PAGE_TEMPLATE_PDF_PATH);
        if (!pageTemplatePdfResponse.ok) {
            alert("Failed to load the PDF file.");
            return;
        }
        pageTemplatePdfBytes = await pageTemplatePdfResponse.arrayBuffer();
    } else {
        const templatePdf = await PDFDocument.create();
        const templateWidth = 8.85 * 72
        const templateHeight = 12.28 * 72
        templatePdf.addPage([templateWidth, templateHeight])
        pageTemplatePdfBytes = await templatePdf.save();
    }


    const pdfDoc = await PDFDocument.create();

    const paddingArray = Array(startingPosition).fill(null);
    const labelsDataPrepared = [...paddingArray, ...labelsData]
    const labelsDataSplitted = splitArrayIntoGroups(labelsDataPrepared, 4)

     for (let i = 0; i < labelsDataSplitted.length; i++) {
        pdfDoc.addPage();
    }

    await Promise.all(labelsDataSplitted.map(async (data, index) => {
        await generateSmallLabelsPagePDF(data, pdfDoc, singleLabelPdfBytes, pageTemplatePdfBytes, index);
    }))

    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], {type: "application/pdf"});
    const url = URL.createObjectURL(blob);
    window.open(url);
}

export default generateSmallLabelsPDF