import {PDFDocument, StandardFonts} from "pdf-lib";
import parseLabelData from "@/utils/parseLabelData";

const generateBigLabelPagePDF = async (rawLabelData, pdfDoc, templatePdfBytes, pageIndex) => {
    const templateDocument = await PDFDocument.load(templatePdfBytes);
    const templatePage = templateDocument.getPage(0);
    const boldFont = await templateDocument.embedFont(StandardFonts.HelveticaBold);

    const labelData = await parseLabelData(rawLabelData);

    const inputBottomData = async (document, page) => {
        const fontSize = 16;
        const bottomDataBoxHeight = 24;
        const bottomDataBoxStartYOffset = 350;
        const bottomDataBoxLabelXOffset = 120;
        const bottomDataBoxValueXOffset = 240;

        const bottomData = [
            {label: "RAW material", value: labelData.name, type: "text"},
            {label: "SAP", value: labelData.sapCode, type: "text"},
            {label: "Champ", value: labelData.champCode, type: "text"},
            {label: "GHS", value: labelData.ghsImages, type: "images"},
            {label: "Verpakking", value: labelData.packaging, type: "text"},
        ];

        bottomData.forEach((data, index) => {
            page.drawText(data.label, {
                size: fontSize,
                y: bottomDataBoxStartYOffset - index * bottomDataBoxHeight,
                x: bottomDataBoxLabelXOffset,
            });
            if (data.type === "text") {
                page.drawText(": " + data.value, {
                    size: fontSize,
                    y: bottomDataBoxStartYOffset - index * bottomDataBoxHeight,
                    x: bottomDataBoxValueXOffset,
                });
            }

            if (data.type === "images") {
                page.drawText(": ", {
                    size: fontSize,
                    y: bottomDataBoxStartYOffset - index * bottomDataBoxHeight,
                    x: bottomDataBoxValueXOffset,
                });

                const imagesPromises = data.value.map(async (item, imageIndex) => {
                    const image = await document.embedPng(item);
                    page.drawImage(image, {
                        y: bottomDataBoxStartYOffset - index * bottomDataBoxHeight - 4,
                        x: bottomDataBoxValueXOffset + 8 + imageIndex * 18,
                        width: 18,
                        height: 18,
                    });
                });

                Promise.all(imagesPromises);
            }
        });
    };

    const inputRightData = async (document, page) => {
        const fontSize = 14;
        const rightDataBoxHeight = 24;
        const rightDataBoxStartYOffset = 350;
        const rightDataBoxLabelXOffset = 575;
        const rightDataBoxValueXOffset = 725;

        const rightData = [
            {label: "Datum ontvangst", value: ""},
            {label: "Houdbaarheidsdatum", value: ""},
            {label: "Locatie", value: labelData.location},
        ];

        rightData.forEach((data, index) => {
            page.drawText(data.label, {
                size: fontSize,
                y: rightDataBoxStartYOffset - index * rightDataBoxHeight,
                x: rightDataBoxLabelXOffset,
            });
            page.drawText(": " + data.value, {
                size: fontSize,
                y: rightDataBoxStartYOffset - index * rightDataBoxHeight,
                x: rightDataBoxValueXOffset,
            });
        });
    };

    const inputSkisData = async (document, page) => {
        const fontSize = 26;
        const skisDataBoxHeight = 38;
        const skisDataBoxStartYOffset = 520;
        const skisDataBoxLeftColumnXOffset = 285;
        const skisDataBoxRightColumnXOffset = 315;

        const skisRightColumnData = [
            labelData.skis.healthChronic,
            labelData.skis.flammability,
            labelData.skis.reactivity,
            labelData.skis.ppe,
        ];

        page.drawText(
            labelData.skis.healthAcute,
            {
                size: fontSize,
                x: skisDataBoxLeftColumnXOffset,
                y: skisDataBoxStartYOffset,
                font: boldFont
            }
        )

        skisRightColumnData.forEach((data, index) => {
            page.drawText(data, {
                size: fontSize,
                y: skisDataBoxStartYOffset - index * skisDataBoxHeight,
                x: skisDataBoxRightColumnXOffset,
                font: boldFont
            })
        })
    }

    await inputBottomData(templateDocument, templatePage);
    await inputRightData(templateDocument, templatePage);
    await inputSkisData(templateDocument, templatePage)

    const [sourcePage] = await pdfDoc.copyPages(templateDocument, [0]);
    pdfDoc.removePage(pageIndex)
    pdfDoc.insertPage(pageIndex, sourcePage)
}

export default generateBigLabelPagePDF