const splitArrayIntoGroups = (array, groupSize) => {
    return array.reduce((acc, item, index) => {
        const groupIndex = Math.floor(index / groupSize);
        if (!acc[groupIndex]) {
            acc[groupIndex] = [];
        }
        acc[groupIndex].push(item);
        return acc;
    }, []);
}

export default splitArrayIntoGroups