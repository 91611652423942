<template>
  <Dialog
      :modal="true"
      :style="{ width: '95vw', maxWidth: '600px', lineHeight: '30px' }"
      :visible.sync="showLocal"
      class="table__dialog"
      position="center"
  >
    <template #header>
      <h3 class="mb-0">Export Labels as PDF</h3>
    </template>

    <div v-if="currentStep === 'modeSelection'">
      <div class="flex justify-content-evenly h-10rem">
        <div>
            <div
                v-for="position in 4"
                :key="position"
                :class="{ selected:  exportMode === 'smallLabels' && startPosition === position - 1, first: position - 1 === 0 }"
                class="mode-option w-6rem h-2rem"
                @click="startPosition = position - 1; exportMode = 'smallLabels'"
            >
              {{ position }}
            </div>
        </div>
          <div>
            <div
                :class="{ selected: exportMode === 'bigLabel', first: true }"
                class="mode-option w-6rem h-8rem"
                @click="exportMode = 'bigLabel'"
            >
            </div>
          </div>
      </div>
      <div class="flex justify-content-end mt-1">
        <Button
            :disabled="!exportMode"
            icon="pi pi-download"
            label="Export"
            type="button"
            @click="onExport"
        />
      </div>
    </div>
  </Dialog>
</template>

<script>
import generateSmallLabelsPDF from "@/utils/generateSmallLabelsPDF";
import generateBigLabelsPDF from "@/utils/generateBigLabelsPDF";

export default {
  props: {
    selectedMaterials: Array,
    show: Boolean,
  },
  data() {
    return {
      currentStep: "modeSelection",
      exportMode: null,
      startPosition: 0,
    };
  },
  computed: {
    showLocal: {
      get() {
        return this.show;
      },
      set(value) {
        if (!value) {
          this.resetDialog();
        }
        this.$emit("hide");
      },
    },
  },
  methods: {
    async onExport() {
      if (this.exportMode === "smallLabels") {
        await generateSmallLabelsPDF(this.selectedMaterials, this.startPosition);
      } else {
        await generateBigLabelsPDF(this.selectedMaterials);
      }
      this.resetDialog()
      this.$emit("hide");
    },
    resetDialog() {
      this.currentStep = "modeSelection";
      this.exportMode = null;
      this.startPosition = null;
    },
  },
};
</script>

<style scoped>
.mode-option {
  display: flex;
  align-items: center;
  justify-content: center;
  border: black solid 2px;
  border-top: none;
  width: 6rem;
  height: 2rem;
}

.mode-option.first {
  border-top: black solid 2px;
}

.mode-option.selected {
  background-color: black;
  color: white;
}
</style>
