import {PDFDocument} from "pdf-lib";
import parseLabelData from "@/utils/parseLabelData";
import generateSingleSmallLabelPDF from "@/utils/generateSingleSmallLabelPDF";

const generateSmallLabelsPagePDF = async (labelsData, pdfDoc, singleLabelPdfBytes, templatePdfBytes, pageIndex) => {
    const templateDocument = await PDFDocument.load(templatePdfBytes);
    const templatePage = templateDocument.getPage(0);
    const {width, height} = templatePage.getSize();

    const documentPaddingH = 28;
    const documentPaddingL = 50;
    const documentPaddingR = 10;
    const separationLineHeight = 3;
    const partHeight = (height - documentPaddingH * 2) / 4 - separationLineHeight;
    const partWidth = (width - documentPaddingL - documentPaddingR);
    const paddingY = 10;
    const paddingL = 20;
    const paddingR = 10;

    await Promise.all(labelsData.map(async (rawLabelData, i) => {
        if (!rawLabelData) {
            return Promise.resolve();
        }

        const labelData = await parseLabelData(rawLabelData);
        const yOffset = height - (i + 1) * partHeight - documentPaddingH;

        const labelPageBytes = await generateSingleSmallLabelPDF(
            singleLabelPdfBytes,
            labelData,
        );
        const labelDocument = await PDFDocument.load(labelPageBytes);
        const labelPage = labelDocument.getPage(0);
        const embeddedPage = await templateDocument.embedPage(labelPage);

        templatePage.drawPage(embeddedPage, {
            x: documentPaddingL + paddingL,
            y: yOffset + paddingY,
            width: partWidth - 2 * paddingR,
            height: partHeight - 2 * paddingY,
        });
    }))

    const [sourcePage] = await pdfDoc.copyPages(templateDocument, [0]);
    pdfDoc.removePage(pageIndex)
    pdfDoc.insertPage(pageIndex, sourcePage)
}

export default generateSmallLabelsPagePDF