<template>
	<div class="table">
		<div class="table__title">{{ title }}</div>

		<Dialog class="table__dialog" :visible.sync="showReceivedForClientDialog" :style="{ 'width': '40vw', 'line-height': '30px' }" position="top" :modal="true">
			<template #header>
				<strong class="text-lg">{{ selectedPurchase.sapcode }} - {{ selectedPurchase.description }}</strong>
			</template>
			<div class="mb-4">
				{{ selectedPurchase.packaging }} <span v-if="selectedPurchase.weight">| {{ parseFloat(selectedPurchase.weight).toFixed(2) }} kg</span>
			</div>
			<div class="flex mb-2">
				<div class="">Received quantity:</div>
				<div class="">
					<InputNumber v-model="quantitySelectorReceivedPurchase" mode="decimal" class="ml-2" style="width: 4rem" />
				</div>
			</div>
			<div class="flex mb-2">
				<div>Date received:</div>
				<div>
					<Calendar class="ml-2" v-model="calendarDate" appendTo="body" dateFormat="dd/mm/yy" />
				</div>
			</div>
			<div>
				<div class="w-full mb-3">
					<TextArea class="w-full" v-model="comments" rows="5" placeholder="Comments..." />
				</div>
			</div>
			<template #footer>
				<Button :disabled="!calendarDate || !quantitySelectorReceivedPurchase || quantitySelectorReceivedPurchase < 1" @click="receivedForClient()" label="Submit received" icon="pi pi-check" iconPos="left" class="p-button-sm ml-2" />
			</template>
		</Dialog>

		<Dialog class="table__dialog" :visible.sync="showDeliveredDialog" :style="{ 'width': '40vw', 'line-height': '30px' }" position="top" :modal="true">
			<template #header>
				<strong class="text-lg">{{ selectedPurchase.sapcode }} - {{ selectedPurchase.description }}</strong>
			</template>
			<div class="mb-4">
				{{ selectedPurchase.packaging }} <span v-if="selectedPurchase.weight">| {{ selectedPurchase.weight }} kg</span>
			</div>
			<div class="flex mb-2">
				<div class="">Received quantity:</div>
				<div>
					<InputNumber v-model="quantitySelectorReceivedPurchase" mode="decimal" class="ml-2" style="width: 4rem" />
				</div>
			</div>
			<div class="flex mb-2">
				<div>Date received:</div>
				<div>
					<Calendar class="ml-2" style="" v-model="calendarDate" appendTo="body" dateFormat="dd/mm/yy" />
				</div>
			</div>
			<div class="flex mb-2" v-if="selectedPurchase && selectedPurchase.rawmaterial">
				<div>Material date In:</div>
				<div>
					<Calendar class="ml-2" style="" v-model="calendarDateIn" appendTo="body" dateFormat="dd/mm/yy" />
				</div>
				<div v-if="1 == 2 && calendarDateIn && selectedPurchase.rawmaterial.expiration_days">
					{{ selectedPurchase.rawmaterial.expiration_days }}
				</div>
				<div class="ml-2 text-sm" v-if="calendarDateIn && selectedPurchase.rawmaterial.expiration_days">Expiration date: {{ $moment(calendarDateIn).add(selectedPurchase.rawmaterial.expiration_days, "days").format("DD-MM-YYYY") }}</div>
			</div>
			<div>
				<div class="w-full mb-3">
					<TextArea class="w-full" v-model="comments" rows="5" placeholder="Comments..." />
				</div>
			</div>
			<template #footer>
				<Button :disabled="!calendarDate || !calendarDateIn || !quantitySelectorReceivedPurchase || quantitySelectorReceivedPurchase < 1" @click="submitReceived()" label="Submit received" icon="pi pi-check" iconPos="left" class="p-button-sm ml-2" />
			</template>
		</Dialog>

		<DataTable :paginator="true" :rows="50" responsiveLayout="scroll" paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" :rowsPerPageOptions="[10, 20, 50, 100]" class="p-datatable-sm p-datatable-striped datatablebug boxshadow" :value="purchasesFiltered" sortField="number" :sortOrder="-1" dataKey="id" colspan="14" showGridlines :filters.sync="filters" filterDisplay="row">
			<template #header>
				<div class="flex justify-content-between align-items-center">
					<div class="flex-grow-0 pl-4">
						<Button type="button" icon="pi pi-file-excel" class="p-button-success p-button-outlined" @click="exportToExcel" />
					</div>
					<div class="flex-grow-1"></div>
				</div>
			</template>
			<Column field="number" header="Number" sortable>
				<template #body="{ data }"> {{ data.number }}</template>
				<template #filter="{ filterModel, filterCallback }">
					<InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter" />
				</template>
			</Column>
			<Column field="orderline" header="Client order">
				<template #body="{ data }">
					<div v-if="getOrderlineFromID(data.orderline)">{{ getOrderlineFromID(data.orderline).number }}</div></template
				>
			</Column>
			<Column field="orderdate" header="Order date" key="orderdate">
				<template #body="{ data }">
					{{ data.date_created | moment("DD-MM-YYYY") }}
				</template>
			</Column>
			<Column field="purpose" header="Purpose">
				<template #body="{ data }">
					<Badge :value="data.purpose" :severity="data.purpose == 'Client' ? 'warning' : 'info'" />
				</template>
			</Column>
			<Column field="receiveStatus" header="Status" sortable>
				<template #body="{ data }">
					<Badge v-if="data.receiveStatus == 'Fulfilled'" :value="data.receiveStatus" severity="success" />
					<Badge v-else-if="data.receiveStatus == 'Partially received'" :value="data.receiveStatus" severity="warning" />
					<Badge v-else :value="data.receiveStatus" severity="danger" />
				</template>
			</Column>

			<Column field="supplier" header="Supplier">
				<template #body="{ data }">
					<span v-if="data.supplier">
						{{ data.supplier.code }}
					</span>
					<span v-if="data.supplier && data.supplier.gnl"> | {{ data.supplier.gnl }} </span>
				</template>
			</Column>

			<Column field="costplace" header="Costplace"> </Column>

			<Column field="supplier.delivery_days" header="Delivery days" key="supplier.delivery_days"> </Column>
			<Column field="deliverbefore" header="Deliver before" key="deliverbefore">
				<template #body="{ data }">
					{{ deliverbefore(data) }}
				</template>
			</Column>
			<Column field="sapcode" header="SAP">
				<template #body="{ data }">
					{{ data.sapcode }}
				</template>
				<template #filter="{ filterModel, filterCallback }">
					<InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter" />
				</template>
			</Column>
			<Column field="champcode" header="Champ">
				<template #body="{ data }">
					{{ data.champcode }}
				</template>
				<template #filter="{ filterModel, filterCallback }">
					<InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter" />
				</template>
			</Column>
			<Column field="description" header="Description">
				<template #body="{ data }"> {{ data.description }} </template>
				<template #filter="{ filterModel, filterCallback }">
					<InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter" />
				</template>
			</Column>
			<Column field="packaging" header="Packaging">
				<template #body="{ data }"> {{ data.packaging }} </template>
			</Column>
			<Column field="weight" header="Weight">
				<template #body="{ data }"> {{ parseFloat(data.weight).toFixed(2) }} kg </template>
			</Column>

			<Column field="quantity_received" header="Received amount" key="quantity_received"> </Column>
			<Column field="quantity" header="Ordered Quantity" key="quantity"> </Column>

			<Column field="Actions" header=" " key="actions">
				<template #body="{ data }">
					<div class="flex p-ai-center">
						<Button type="button" icon="pi pi-trash" class="p-button-rounded p-button-danger mr-2" v-tooltip.top="'Delete'" @click="deletePurchaseOrder(data)" />
						<div v-if="data.purpose == 'Client'">
							<Button :disabled="data.receiveStatus == 'Fulfilled'" @click="receivedForClientDialog(data)" label="Received" icon="pi pi-check" iconPos="left" class="p-button-sm ml-2 p-button-warning" />
						</div>
						<div v-if="data.purpose == 'Stock'" class="flex p-ai-center"><Button :disabled="data.receiveStatus == 'Fulfilled'" @click="showDeliveredDialogHandler(data)" label="Add to stock" icon="pi pi-check" iconPos="left" class="p-button-sm ml-2" /></div>
					</div>
				</template>
			</Column>
		</DataTable>
		<Toast />
		<ConfirmDialog></ConfirmDialog>
	</div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";

import { FilterMatchMode, FilterOperator } from "primevue/api/";

import * as XLSX from "xlsx";

export default {
	data() {
		return {
			title: "Purchase Orders",
			filters: {
				number: {
					value: null,
					matchMode: FilterMatchMode.CONTAINS,
				},
				sapcode: {
					value: null,
					matchMode: FilterMatchMode.CONTAINS,
				},
				champcode: {
					value: null,
					matchMode: FilterMatchMode.CONTAINS,
				},
				description: {
					value: null,
					matchMode: FilterMatchMode.CONTAINS,
				},
			},
			showReceivedForClientDialog: false,
			showDeliveredDialog: false,
			selectedPurchase: {},
			quantitySelectorReceivedPurchase: 0,
			calendarDate: null,
			calendarDateIn: null,
			comments: "",
		};
	},
	async mounted() {
		await this.getData();
	},
	methods: {
		getData() {
			this.$store.dispatch("getData");
		},
		getOrderlineFromID(orderline_id) {
			let self = this;
			if (self.orderlines && self.orderlines.length > 0 && orderline_id) {
				return _.find(self.orderlines, { id: orderline_id });
			}
			return null;
		},
		deliverbefore(order) {
			if (order.supplier) {
				return moment(order.date_created).add(order.supplier.delivery_days, "d").format("DD-MM-YYYY");
			}
			return null;
		},
		receivedForClientDialog(purchase) {
			this.calendarDate = null;
			this.quantitySelectorReceivedPurchase = 0;
			this.showReceivedForClientDialog = true;
			this.selectedPurchase = purchase;
			this.comments = null;
		},
		async receivedForClient(purchase) {
			await this.$store.dispatch("submitReceived", {
				date_received: this.calendarDate,
				id: this.selectedPurchase.id,
				quantity_received: this.quantitySelectorReceivedPurchase,
				comments: this.comments,
			});
			await this.$toast.add({
				severity: "success",
				summary: "Received",
				detail: "Received quantity submitted!",
				life: 3000,
			});
			this.showReceivedForClientDialog = false;
			await this.getData();
		},

		showDeliveredDialogHandler(purchase) {
			this.calendarDate = null;
			this.calendarDateIn = null;
			this.quantitySelectorReceivedPurchase = 0;
			this.showDeliveredDialog = true;
			this.selectedPurchase = purchase;
			this.comments = null;
		},
		async submitReceived() {
			await this.$store.dispatch("submitReceived", {
				date_received: this.calendarDate,
				id: this.selectedPurchase.id,
				quantity_received: this.quantitySelectorReceivedPurchase,
			});
			this.showDeliveredDialog = false;
			await this.$store.dispatch("createStock", {
				id: this.selectedPurchase.id,
				quantity_received: this.quantitySelectorReceivedPurchase,
				dateIn: this.calendarDateIn,
				rawmaterialId: this.selectedPurchase.rawmaterial.id,
				comments: this.comments,
			});
			await this.$toast.add({
				severity: "success",
				summary: "Received",
				detail: "Received quantity submitted and added to stock!",
				life: 3000,
			});
			// this.$router.go()
			await this.getData();
		},
		async deletePurchaseOrder(purchase) {
			await this.$confirm.require({
				message: "Are you sure you want to delete?",
				header: "Delete",
				icon: "pi pi-exclamation-triangle",
				accept: () => {
					this.$store.dispatch("deletePurchaseOrder", purchase);
					this.$toast.add({
						severity: "success",
						summary: "Purchase order",
						detail: "Order is deleted!",
						life: 3000,
					});
				},
				reject: () => {},
			});
		},

		exportToExcel() {
			let self = this;

			let excelData = [];

			if (self.purchasesFiltered && self.purchasesFiltered.length > 0) {
				let purchases = _.orderBy(self.purchases, ["number"], ["desc"]);
				purchases.forEach((purchase) => {
					excelData.push({
						number: purchase.number,
						date: purchase.date_created ? self.$moment(purchase.date_created).format("DD-MM-YYYY") : null,
						purpose: purchase.purpose,
						status: purchase.receiveStatus,
						supplier: purchase.supplier ? purchase.supplier.code + " | " + purchase.supplier.gnl : null,
						deliveryDays: purchase.supplier ? purchase.supplier.delivery_days : null,
						deliveryBefore: self.deliverbefore(purchase) ? self.deliverbefore(purchase) : null,
						sap: purchase.sap_code,
						champ: purchase.champ_code,
						description: purchase.description,
						packaging: purchase.packaging,
						weight: purchase.weight ? parseFloat(purchase.weight).toFixed(2) : null,
						quantityReceived: purchase.quantity_received,
						quantity: purchase.quantity,
					});
				});

				// Convert data to worksheet
				const worksheet = XLSX.utils.json_to_sheet(excelData);

				// Create a new workbook
				const workbook = XLSX.utils.book_new();

				// Append the worksheet to the workbook
				XLSX.utils.book_append_sheet(workbook, worksheet, "PurchaseOrders");

				// Generate an Excel file and trigger the download
				XLSX.writeFile(workbook, "PurchaseOrders.xlsx");
			}
		},
	},
	computed: {
		...mapState({
			purchases: (state) => state.data.purchases,
			orderlines: (state) => state.orderlines,
		}),
		purchasesFiltered() {
			let self = this;
			if (self.purchases && self.purchases.length > 0) {
				return _.filter(self.purchases, function (purchase) {
					//console.log(purchase.receiveStatus);
					return purchase.toDeliver > 0;
				});
			}
		},
	},
};
</script>

<style lang="scss">
.table {
	padding: 20px;
	&__title {
		color: #24222f;
		text-align: left;
		font-weight: bold;
		margin: 20px 0;
		font-size: 28px;
	}
	&__footer {
		text-align: left;
	}
}
</style>
