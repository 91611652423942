<template>
	<div class="cart">
		<div class="cart__title">
			{{ title }}
		</div>

		<DataTable :rows="20" responsiveLayout="scroll" class="p-datatable-sm p-datatable-striped" :value="cart" showGridlines>
			<Column field="sapcode" header="SAP">
				<template #body="{ data }"> {{ data.sapcode }} </template>
			</Column>
			<Column field="description" header="Description">
				<template #body="{ data }"> {{ data.description }} </template>
			</Column>
			<Column field="Quantity" header="Quantity" key="quantity">
				<template #body="{ data }">
					{{ data.quantityToAdd }}
				</template>
			</Column>
			<Column field="Urgent" header="Urgent" key="urgent">
				<template #body="{ data }">
					<div class="p-field-checkbox mt-3">
						<Checkbox id="binary" v-model="data.urgent" :binary="true" />
					</div>
				</template>
			</Column>
			<Column field="" header="" key="id" :exportable="false">
				<template #body="slotProps">
					<Button @click="removeFromCart(slotProps.data)" label="Remove" icon="pi pi-times" iconPos="left" class="p-button-sm p-button-danger" />
				</template>
			</Column>
			<template #empty>
				<h3>Your cart is empty.</h3>
			</template>
		</DataTable>
		<div v-if="viewMode == 'look'">
			<div class="py-4">
				<Button @click="$router.push('/')" label="Continue shopping" icon="pi pi-arrow-left" iconPos="left" class="p-button p-button-outlined mr-2" />
				<Button v-if="cart && cart.length > 0" @click="viewMode = 'order'" label="Place order" icon="pi pi-arrow-right" iconPos="left" class="p-button" />
			</div>
		</div>
		<div class="bg-white p-4" v-if="cart && cart.length > 0 && viewMode == 'order'">
			<ValidationObserver ref="observer">
				<div v-if="deliverymethods">
					<div class="flex align-items-center mb-4" v-for="(method, i) in deliverymethods" :key="i">
						<div class="flex-shrink-0 mr-2">
							<RadioButton :id="method.name" :name="method.name" :value="method" v-model="deliverymethod" />
						</div>
						<div class="flex-grow-1">
							<label :for="method.name" class="font-bold">{{ method.title }}</label>
							<div>{{ method.description }}</div>
						</div>
					</div>
				</div>

				<div class="flex align-items-center mb-4" v-if="$store.state.data.millingrooms && deliverymethod && deliverymethod.name == 'Millingroom'">
					<div class="flex align-items-center">
						<div class="flex-grow-1">
							<ValidationProvider rules="required" name="Millingroom" v-slot="{ errors }">
								<Dropdown v-model="millingroom" :options="$store.state.data.millingrooms" optionLabel="name" placeholder="Millingroom..." class="w-full" />
								<div class="error">{{ errors[0] }}</div>
							</ValidationProvider>
						</div>
					</div>
				</div>
				<div class="flex align-items-center mb-4">
					<div class="flex align-items-center">
						<div class="flex-grow-1 font-bold">Send a confirmation of this order to:</div>
					</div>
				</div>

				<div>
					<div class="w-full md:w-6 lg:w-4">
						<div v-for="(input, i) in emailAddresses" class="mb-3" :key="i">
							<div class="p-inputgroup">
								<Button v-if="i > 0" icon="pi pi-times" class="p-button-danger" @click="removeEmailAddress(i)" />
								<Button v-else icon="pi pi-times" class="p-button-secondary" disabled style="opacity: 0.25" />
								<InputText v-model="emailAddresses[i]" type="text" placeholder="Email" />
								<Button v-if="i == emailAddresses.length - 1" @click="addEmailAddress(i)" label="" icon="pi pi-plus" iconPos="left" />
							</div>
						</div>

						<div>
							<div class="w-full mb-3">
								<ValidationProvider rules="required" name="Costplace" v-slot="{ errors }">
									<Dropdown v-model="costplace" :options="costplaceOptions" class="w-full" placeholder="Costplace..." editable />
									<div class="error">{{ errors[0] }}</div>
								</ValidationProvider>
							</div>
						</div>

						<div>
							<div class="w-full mb-3">
								<TextArea class="w-full" v-model="comments" rows="5" placeholder="Comments..." />
							</div>
						</div>
					</div>
					<div class="">
						<div class="p-field">
							<Button @click="submitOrder" :disabled="cart.length == 0" class="p-button-lg p-button-success" icon="pi pi-check" label="Place Order" />
						</div>
					</div>
				</div>
			</ValidationObserver>
		</div>
		<Toast />
	</div>
</template>

<script>
import { mapState } from "vuex";

export default {
	name: "myorder",
	data() {
		return {
			title: "My Order",
			deliverymethod: this.$store.state.deliverymethods ? this.$store.state.deliverymethods[0] : null,
			costplace: "",
			comments: "",
			emailAddresses: [this.$store.state.user && this.$store.state.user.email ? this.$store.state.user.email : ""],
			viewMode: "look",
			millingroom: null,
			costplaceOptions: ["ENL3000530", "ENL3000535", "ENL3003535", "GNL3000530", "GNL3002530", "SNL3CEO450", "SNL3EHQ310", "SNL3000930", "SNL3001320", "SNL3001710", "SNL3001720", "SNL3001810", "SNL3001930", "SNL3002310", "SNL3002320", "SNL3002420", "SNL3002720", "SNL3002930", "SNL3003320", "SNL3004320", "SNL3005710", "SNL3002320", "ZNL0105720", "ZNL0106720", "ZNL0101720", "ZNL0001770", "ZNL0001750", "ZNL0104720", "ZNL0102720", "ZNL0002750", "ZNL4104650", "ZNL7101310", "ZNL1000420"],
		};
	},
	computed: {
		...mapState({
			cart: (state) => state.cart,
			deliverymethods: (state) => state.deliverymethods,
		}),
	},
	methods: {
		removeFromCart(item) {
			this.$store.dispatch("removeFromCart", item);
		},
		async submitOrder() {
			let observer = this.$refs["observer"];
			if (observer.flags.valid != true) {
				observer.validate();
				return;
			}

			let response = null;

			let self = this;

			if (!this.$store.state.data) {
				return;
			}

			//console.log(self.$directus && self.$directus.auth && self.$directus.auth.token ? self.$directus.auth.token : null);
			//console.log(this.$store.state.data.rawmaterials);
			//return;

			try {
				response = await this.$store.dispatch("submitStockOrder", {
					deliverymethod: this.deliverymethod,
					millingroom: this.millingroom,
					costplace: this.costplace,
					comments: this.comments,
					user: this.$store.state.user.id,
					name: this.$store.state.user.first_name + " " + this.$store.state.user.last_name,
					email: this.$store.state.user.email,
				});
				//console.log("response", response);

				let orderlines = response.orderlinesResponse.raw.data;
				orderlines.forEach((orderline) => {
					let rawmaterial = orderline.rawmaterial ? _.find(this.$store.state.data.rawmaterials, { id: orderline.rawmaterial }) : null;
					let supplier = orderline.supplier ? _.find(this.$store.state.data.suppliers, { id: orderline.supplier }) : null;

					orderline.urgent = orderline.urgent ? "Yes" : "No";
					orderline.location = rawmaterial && rawmaterial.location ? rawmaterial.location : "";
					orderline.weight = rawmaterial && rawmaterial.weight ? rawmaterial.weight : "";
					orderline.packaging = rawmaterial && rawmaterial.packaging ? rawmaterial.packaging : "";
					orderline.suppliercode = supplier && supplier.code ? supplier.code : "";
					orderline.hasNotes = false;
					orderline.notes = [];
					orderline.pickStatus = "";
				});

				let email_to = [];

				if (self.emailAddresses) {
					self.emailAddresses.forEach((emailAddress) => {
						email_to.push({ Email: emailAddress });
					});
				}

				let email_subject = "PPG Raw Materials - Order " + response.orderNumber;

				let email_variables = {
					order: {
						number: response.orderNumber,
						date: self.$moment(response.createdOrder.datetime).format("DD-MM-YYYY"),
						name: response.createdOrder.name,
						email: response.createdOrder.email,
						deliverymethod: self.deliverymethod.name,
						deliverymethod_title: self.deliverymethod.title,
						deliverymethod_description: self.deliverymethod.description,
						millingroom: self.millingroom ? self.millingroom.name : "",
						costplace: response.createdOrder.costplace,
						comments: response.createdOrder.comments,
					},
					orderlines: orderlines,
				};

				//console.log(email_variables);

				if (self.$directus && self.$directus.auth && self.$directus.auth.token) {
					self.$axios.post(
						self.$store.state.DIRECTUS_URL + "mail/order",
						{
							to: [{ Email: "AMS-MB.R&DWarehouse@ppg.com" }],
							bcc: [{ Email: "robert@zwaanbrandservices.com" }, { Email: "zomerstorm@proton.me" }],
							subject: email_subject,
							variables: email_variables,
						},
						{
							headers: {
								Authorization: "Bearer " + self.$directus.auth.token,
							},
						}
					);
					if (email_to.length > 0) {
						self.$axios.post(
							self.$store.state.DIRECTUS_URL + "mail/order",
							{
								to: email_to,
								subject: email_subject,
								variables: email_variables,
							},
							{
								headers: {
									Authorization: "Bearer " + self.$directus.auth.token,
								},
							}
						);
					}
				}

				this.$toast.add({
					severity: "success",
					summary: "Order success!",
					detail: "Order successfully placed!",
					life: 3000,
				});
			} catch {
				this.$toast.add({
					severity: "error",
					summary: "Something whent wrong Message",
					detail: "Try again later",
					life: 3000,
				});
			}
		},
		addEmailAddress() {
			this.emailAddresses.push("");
		},
		removeEmailAddress(i) {
			this.emailAddresses.splice(i, 1);
		},
	},
};
</script>

<style lang="scss">
.cart {
	padding: 20px;
	max-width: 1600px;
	margin: 0 auto;

	&__title {
		color: #24222f;
		text-align: left;
		font-weight: bold;
		margin: 20px 0;
		font-size: 28px;
	}
	&__form {
		.p-field-radiobutton:first-child {
			margin-bottom: 0px;
		}
		font-size: 20px;
		text-align: left;
		padding: 20px;
		background: white;
		line-height: 40px;
	}
	&__form-small-text {
		font-size: 14px;
	}
}
</style>
