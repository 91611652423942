import generateImageLink from "@/utils/generateImageLink";

const parseLabelData = async (data) => {
    const images = await Promise.all(
        data.ghs.map(async (item) => {
            const link = await generateImageLink(item.GHS_id.image.id);
            const response = await fetch(link);
            return await response.arrayBuffer();
        })
    );

    return {
        skis: {
            healthAcute: data.shis_health_acute,
            healthChronic: data.shis_health_chronic,
            flammability: data.shis_flammability,
            reactivity: data.shis_reactivity,
            ppe: data.shis_ppe,
        },

        name: data.name,
        sapCode: data.sapcode,
        champCode: data.champcode,
        ghsImages: images,
        packaging: data.packaging,
        location: data.location,
    };
};

export default parseLabelData;
