import { PDFDocument, StandardFonts } from "pdf-lib";
import index from "vuex";

const generateSingleSmallLabelPDF = async (pdfFileBytes, labelData) => {
    const singleLabelPdfDoc = await PDFDocument.load(pdfFileBytes);
    const labelPage = singleLabelPdfDoc.getPage(0);
    const boldFont = await singleLabelPdfDoc.embedFont(StandardFonts.HelveticaBold);

    const inputBottomData = async (document, page) => {
        const fontSize = 14;
        const bottomDataBoxHeight = 19;
        const bottomDataBoxStartYOffset = 80;
        const bottomDataBoxLabelXOffset = 0;
        const bottomDataBoxValueXOffset = 120;

        const bottomData = [
            {label: "RAW material", value: labelData.name, type: "text"},
            {label: "SAP", value: labelData.sapCode, type: "text"},
            {label: "Champ", value: labelData.champCode, type: "text"},
            {label: "GHS", value: labelData.ghsImages, type: "images"},
            {label: "Verpakking", value: labelData.packaging, type: "text"},
        ];

        bottomData.forEach((data, index) => {
            page.drawText(data.label, {
                size: fontSize,
                y: bottomDataBoxStartYOffset - index * bottomDataBoxHeight,
                x: bottomDataBoxLabelXOffset,
            });
            if (data.type === "text") {
                page.drawText(": " + data.value, {
                    size: fontSize,
                    y: bottomDataBoxStartYOffset - index * bottomDataBoxHeight,
                    x: bottomDataBoxValueXOffset,
                });
            }

            if (data.type === "images") {
                page.drawText(": ", {
                    size: fontSize,
                    y: bottomDataBoxStartYOffset - index * bottomDataBoxHeight,
                    x: bottomDataBoxValueXOffset,
                });

                const imagesPromises = data.value.map(async (item, imageIndex) => {
                    const image = await document.embedPng(item);
                    page.drawImage(image, {
                        y: bottomDataBoxStartYOffset - index * bottomDataBoxHeight - 4,
                        x: bottomDataBoxValueXOffset + 8 + imageIndex * 16,
                        width: 16,
                        height: 16,
                    });
                });

                Promise.all(imagesPromises);
            }
        });
    };

    const inputRightData = async (document, page) => {
        const fontSize = 12;
        const rightDataBoxHeight = 16;
        const rightDataBoxStartYOffset = 180;
        const rightDataBoxLabelXOffset = 350;
        const rightDataBoxValueXOffset = 470;

        const rightData = [
            {label: "Datum ontvangst", value: ""},
            {label: "Houdbaarheidsdatum", value: ""},
            {label: "Locatie", value: labelData.location},
        ];

        rightData.forEach((data, index) => {
            page.drawText(data.label, {
                size: fontSize,
                y: rightDataBoxStartYOffset - index * rightDataBoxHeight,
                x: rightDataBoxLabelXOffset,
            });
            page.drawText(": " + data.value, {
                size: fontSize,
                y: rightDataBoxStartYOffset - index * rightDataBoxHeight,
                x: rightDataBoxValueXOffset,
            });
        });
    };

    const inputSkisData = async (document, page) => {
        const fontSize = 16;
        const skisDataBoxHeight = 22;
        const skisDataBoxStartYOffset = 175;
        const skisDataBoxLeftColumnXOffset = 125;
        const skisDataBoxRightColumnXOffset = 148;

        const skisRightColumnData = [
            labelData.skis.healthChronic,
            labelData.skis.flammability,
            labelData.skis.reactivity,
            labelData.skis.ppe,
        ];

        page.drawText(
            labelData.skis.healthAcute,
            {
                size: fontSize,
                x: skisDataBoxLeftColumnXOffset,
                y: skisDataBoxStartYOffset,
                font: boldFont
            }
        )

        skisRightColumnData.forEach((data, index) => {
            page.drawText(data, {
                size: fontSize,
                y: skisDataBoxStartYOffset - index * skisDataBoxHeight,
                x: skisDataBoxRightColumnXOffset,
                font: boldFont
            })
        })
    }

    await inputBottomData(singleLabelPdfDoc, labelPage);
    await inputRightData(singleLabelPdfDoc, labelPage);
    await inputSkisData(singleLabelPdfDoc, labelPage)
    const pdfBytes = await singleLabelPdfDoc.save();
    const blob = new Blob([pdfBytes], {type: "application/pdf"});
    return await blob.arrayBuffer();
}

export default generateSingleSmallLabelPDF