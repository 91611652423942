import { PDFDocument } from "pdf-lib";
import generateBigLabelPagePDF from "@/utils/generateBigLabelPagePDF";

const generateBigLabelsPDF = async (labelsData, useTemplate=false) => {
    let templatePdfBytes
    if (useTemplate) {
        const PDF_PATH = "pdf/big_label.pdf";
        const templatePdfResponse = await fetch(PDF_PATH);
        if (!templatePdfResponse.ok) {
            alert("Failed to load the PDF file.");
            return;
        }
        templatePdfBytes = await templatePdfResponse.arrayBuffer();
    } else {
        const templatePdf = await PDFDocument.create();
        const templateHeight = 8.85 * 72
        const templateWidth = 12.28 * 72
        templatePdf.addPage([templateWidth, templateHeight])
        templatePdfBytes = await templatePdf.save();
    }

    const pdfDoc = await PDFDocument.create();

    for (let i = 0; i < labelsData.length; i++) {
        pdfDoc.addPage();
    }

    await Promise.all(labelsData.map(async (data, index) => {
        await generateBigLabelPagePDF(data, pdfDoc, templatePdfBytes, index);
    }))

    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], {type: "application/pdf"});
    const url = URL.createObjectURL(blob);
    window.open(url);
}

export default generateBigLabelsPDF